<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-form-header :showHeader="showParentModelHeader">
				<imofalformsform-header :modelPk="parentModelPk" :parentModelName="parentModelName"></imofalformsform-header>
			</pui-form-header>
			<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
			<!--
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalforms5'">{{ $t('imofalforms5.maintab') }}</v-tab>
			</v-tabs>
			-->
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalforms5'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform5.fieldset.personalinfo')">
										<v-row dense>
											<!-- FAMILYNAME -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`familyname-imofalform5`"
													v-model="model.familyname"
													:label="$t('imofalform5.familyname')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="70"
												></pui-text-field>
											</v-col>
											<!-- GIVENNAME -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`givenname-imofalform5`"
													v-model="model.givenname"
													:label="$t('imofalform5.givenname')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="70"
												></pui-text-field>
											</v-col>
											<!-- NATIONALITY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`nationality-imofalform5`"
													:attach="`nationality-imofalform5`"
													:label="$t('imofalform5.nationality')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mcountries"
													v-model="model"
													reactive
													:itemsToSelect="nationalityItemsToSelect"
													:modelFormMapping="{ countrycode: 'nationality' }"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
												></pui-select>
											</v-col>
											<!-- BIRTHDATE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`birthdate-imofalform5`"
													v-model="model.birthdate"
													:label="$t('imofalform5.birthdate')"
													:disabled="formDisabled"
													required
													toplabel
													:max="getMaxDate()"
												></pui-date-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<!-- BIRTHPLACE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`birthplace-imofalform5`"
													v-model="model.birthplace"
													:label="$t('imofalform5.birthplace')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="35"
												></pui-text-field>
											</v-col>
											<!-- GENDERCODE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`gendercode-imofalform5`"
													:attach="`gendercode-imofalform5`"
													:label="$t('imofalform5.gendercode')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mgender"
													v-model="model"
													reactive
													:itemsToSelect="gendercodeItemsToSelect"
													:modelFormMapping="{ gendercode: 'gendercode' }"
													:itemValue="['gendercode']"
													:itemText="(item) => `${item.genderdescription}`"
													:order="{ genderdescription: 'asc' }"
												></pui-select>
											</v-col>
											<!-- RANKCODE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`rankcode-imofalform5`"
													:attach="`rankcode-imofalform5`"
													:label="$t('imofalform5.rankcode')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="vlupmcrewranks"
													v-model="model"
													:itemsToSelect="rankcodeItemsToSelect"
													:modelFormMapping="{ rankcode: 'rankcode' }"
													:itemValue="['rankcode']"
													:itemText="(item) => `${item.rankdescription}`"
													:order="{ rankdescription: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>

							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform5.fieldset.documentident')">
										<v-row dense>
											<!-- DOCUMENTTYPE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`documenttype-imofalform5`"
													:attach="`documenttype-imofalform5`"
													:label="$t('imofalform5.documenttype')"
													:disabled="formDisabled"
													required
													toplabel
													modelName="mimofalform5typesiddoc"
													v-model="model"
													:itemsToSelect="typesIdDocItemsToSelect"
													:modelFormMapping="{ identdocid: 'identdocid' }"
													:itemValue="['identdocid']"
													:itemText="(item) => `${item.typedescription}`"
												></pui-select>
											</v-col>
											<!-- DOCUMENTNUMBER -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`documentnumber-imofalform5`"
													v-model="model.documentnumber"
													:label="$t('imofalform5.documentnumber')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="35"
												></pui-text-field>
											</v-col>
											<!-- ISSUINGSTATE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`issuingstate-imofalform5`"
													:attach="`issuingstate-imofalform5`"
													:label="$t('imofalform5.issuingstate')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mcountries"
													v-model="model"
													reactive
													:itemsToSelect="issuingstateItemsToSelect"
													:modelFormMapping="{ countrycode: 'issuingstate' }"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
												></pui-select>
											</v-col>
											<!-- EXPIRYDATE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`expirydate-imofalform5`"
													v-model="model.expirydate"
													:label="$t('imofalform5.expirydate')"
													:disabled="formDisabled"
													required
													toplabel
												></pui-date-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<!-- Page Footer -->
					<pui-form-footer>
						<pui-form-footer-btns
							:formDisabled="!havePermissions(['WRITE_IMOFALFORMS5'])"
							:saveDisabled="saving"
							:saveAndNew="saveAndNew"
							:saveAndUpdate="saveAndUpdate"
							:save="save"
							:back="back"
						></pui-form-footer-btns>
					</pui-form-footer>
				</v-tab-item>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
window.addEventListener('load', function () {
	//document.documentElement.style.setProperty('--Y-25', '#dadde0');
	//document.documentElement.style.setProperty('--Y-10', '#FFFFFF');
});
//document.getElementsByClassName('v-input__slot').style.setProperty('background-color', '#FFFFFF');

import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import imofalform5Actions from '../imofalform5menu/ImoFalForm5Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform5-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform5',
			actions: imofalform5Actions.actions,
			pkAttribute: 'imofalformid',
			parentModelName: 'imofalform5menu',
			modeldetail: true,
			tabmodel: 'imofalforms5',
			birthdate: null,
			labelportarrival: this.$t('imofalforms5.portofarrival')
		};
	},

	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
			//document.getElementsByClassName('v-input__slot').style.setProperty('--Y-25', '#FFFFFF');
		},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Meses de 0 a 11
			const day = currentDate.getDate();

			// Formateamos la fecha en el formato YYYY-MM-DD
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		}
	},
	computed: {
		rankcodeItemsToSelect() {
			return [{ rankcode: this.model.rankcode }];
		},
		gendercodeItemsToSelect() {
			return [{ gendercode: this.model.gendercode }];
		},
		typesIdDocItemsToSelect() {
			return [{ identdocid: this.model.identdocid }];
		},
		nationalityItemsToSelect() {
			return [{ countrycode: this.model.nationality }];
		},
		issuingstateItemsToSelect() {
			return [{ countrycode: this.model.issuingstate }];
		}
	},

	updated() {}
};
</script>
